export const API_BASE_URL = 'https://dev.api.askmilo.ai'
// export const API_BASE_URL = 'http://3.17.1.190:5000'
export const MEDIA_URL = 'https://zan-rides-assets.s3.af-south-1.amazonaws.com'

export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/users/auth/login`,
    SignUp: `${API_BASE_URL}/api/users/auth/register/email`,
    me: `${API_BASE_URL}/api/users/me/profile`,
    verifotp: `${API_BASE_URL}/api/v1/admin/auth/verify/otp`,
    verifyEmail: (token) => `${API_BASE_URL}/api/users/auth/verify-email/${token}`,
    forgot: `${API_BASE_URL}/api/users/auth/forgot-password`,
    resetPassword: (token) => `${API_BASE_URL}/api/users/auth/reset-password/${token}`,
    resendverifiemail: `${API_BASE_URL}/api/users/auth/resend/email-verification`,
    profileupdate: `${API_BASE_URL}/api/users/profile/update`,
    passwordupdate: `${API_BASE_URL}/api/users/update/profile-password`,
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/v1/admin/user/counts`
  },
  PROMPT: {
    categoryList: `${API_BASE_URL}/api/users/misc/category`,
    generatePrompt: `${API_BASE_URL}/api/users/prompt/generate`,
    executePrompt: `${API_BASE_URL}/api/users/prompt/execute`,
    savePrompt: `${API_BASE_URL}/api/users/prompt/save`,
    promptList: `${API_BASE_URL}/api/users/prompt`,
    getPromptById: `${API_BASE_URL}/api/users/prompt`,
    myPromptList: `${API_BASE_URL}/api/users/prompt/my`,
    allPromptList: `${API_BASE_URL}/api/users/prompt`,
    mostUsedCategory: `${API_BASE_URL}/api/users/misc/most-used/category`,
    deletePromptById: (id) => `${API_BASE_URL}/api/users/prompt/${id}`,
    download_pdf: `${API_BASE_URL}/api/users/prompt/generate/pdf`,
    download_word: `${API_BASE_URL}/api/users/prompt/generate/word`,
    download_pdf_without_save: `${API_BASE_URL}/api/users/prompt/generate/pdf-without-save`,
    download_word_without_save: `${API_BASE_URL}/api/users/prompt/generate/word-without-save`
  },
  SUBSCRIPTIONS: {
    planList: `${API_BASE_URL}/api/users/subscriptions/listing`,
    addUser: `${API_BASE_URL}/api/users/prompt/add/users`,
    paymentInitialize: `${API_BASE_URL}/api/users/payments/initialize`,
    verifyPayment: `${API_BASE_URL}/api/users/payments/verify`,
    paymentHistory: `${API_BASE_URL}/api/users/payments/transactions`,
    getUserList: `${API_BASE_URL}/api/users/prompt/users`,
    deleteUser: `${API_BASE_URL}/api/users/prompt/users`,
    freeTrial: `${API_BASE_URL}/api/users/payments/free-trial`,
    trialVerified: `${API_BASE_URL}/api/users/payments/save-card`,
    cancelAutoPayment: `${API_BASE_URL}/api/users/payments/cancele-autopayment`,
  }
}
